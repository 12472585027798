const loadImports = async () => {
    if (importPromise) {
        return await importPromise;
    }
    const { createEIP1193Provider } = await import('@web3-onboard/common');
    const importedSDK = await import('@metamask/sdk');
    const MetaMaskSDKConstructor = 
    // @ts-ignore
    importedSDK.MetaMaskSDK || importedSDK.default.MetaMaskSDK;
    if (!MetaMaskSDKConstructor) {
        throw new Error('Error importing and initializing MetaMask SDK');
    }
    return { createEIP1193Provider, MetaMaskSDKConstructor };
};
let importPromise = null;
let sdk = null;
let createInstance;
function metamask({ options }) {
    return () => {
        importPromise = loadImports().catch(error => {
            throw error;
        });
        const getProvider = (_sdk) => {
            const provider = createInstance(_sdk.getProvider(), {});
            provider.disconnect = () => {
                sdk === null || sdk === void 0 ? void 0 : sdk.terminate();
            };
            return provider;
        };
        return {
            label: 'MetaMask',
            getIcon: async () => (await import('./icon.js')).default,
            getInterface: async ({ appMetadata }) => {
                var _a;
                sdk = window.mmsdk || sdk; // Prevent conflict with existing mmsdk instances
                if (sdk) {
                    // Prevent re-initializing instance as it causes issues with MetaMask sdk mobile provider.
                    return {
                        provider: sdk.getProvider(),
                        instance: sdk
                    };
                }
                const { name, icon } = appMetadata || {};
                const base64 = window.btoa(icon || '');
                const appLogoUrl = `data:image/svg+xml;base64,${base64}`;
                const imports = await importPromise;
                // Patch issue with MetaMask SDK, remove after SDK is fixed
                localStorage.removeItem('providerType');
                if (!(imports === null || imports === void 0 ? void 0 : imports.MetaMaskSDKConstructor) ||
                    !(imports === null || imports === void 0 ? void 0 : imports.createEIP1193Provider)) {
                    throw new Error('Error importing and initializing MetaMask SDK');
                }
                const { createEIP1193Provider, MetaMaskSDKConstructor } = imports;
                createInstance = createEIP1193Provider;
                sdk = new MetaMaskSDKConstructor({
                    ...options,
                    dappMetadata: {
                        name: ((_a = options.dappMetadata) === null || _a === void 0 ? void 0 : _a.name) || name || '',
                        base64Icon: appLogoUrl
                    },
                    _source: 'web3-onboard'
                });
                await sdk.init();
                const provider = getProvider(sdk);
                const _request = provider.request;
                provider.request = async ({ method, params }) => {
                    if (sdk === null || sdk === void 0 ? void 0 : sdk.isExtensionActive()) {
                        return window.extension.request({ method, params });
                    }
                    return _request({ method, params });
                };
                return {
                    provider,
                    instance: sdk
                };
            }
        };
    };
}
export default metamask;
